<section class="section">
  <div class="welcome container">
    <div class="columns">
      <div
        class="column welcome__container is-half-desktop is-full-tablet is-full-mobile"
      >
        <h1 class="title is-1 welcome__title">Hi, I'm Carlos Santos</h1>
      </div>
      <div
        class="column welcome__image is-half-desktop is-full-tablet is-full-mobile"
      >
        <figure class="image is-2by2">
          <img
            class="is-square welcome__pic"
            src="../../../assets/carlos.png"
          />
        </figure>
      </div>
    </div>
  </div>
</section>
