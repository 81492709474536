<nav class="nav has-shadow">
  <div class="container is-unselectable">
    <aside class="menu column is-12 is-fullheight section is-hidden-mobile">
      <a [routerLink]="['']">
        <h1 class="menu-label is-hidden-mobile is-size-3">Carlos Santos</h1>
      </a>
      <ul class="menu-list is-size-5">
        <li class="menu__parent">
          <a [routerLink]="['about']" class="menu__parent_item">
            <span class="icon">
              <fa-icon [icon]="faUserCircle"></fa-icon>
            </span>
            About
          </a>
        </li>
        <li class="menu__parent">
          <a
            class="menu__item_expandable menu__parent_item"
            [ngClass]="{ 'is-active': resumeSectionExpanded }"
            [routerLink]="['resume']"
            (click)="clickResumeSection($event)"
          >
            <span class="icon">
              <fa-icon [icon]="faFileUser"></fa-icon>
            </span>
            Resume
            <span class="icon">
              <fa-icon
                [icon]="faCaretDown"
                *ngIf="!resumeSectionExpanded"
              ></fa-icon>
              <fa-icon
                [icon]="faCaretUp"
                *ngIf="resumeSectionExpanded"
              ></fa-icon>
            </span>
          </a>
          <ul
            [@openClose]="resumeSectionExpanded ? 'open' : 'closed'"
            *ngIf="resumeSectionExpanded"
          >
            <li>
              <a [routerLink]="['resume/skills']">
                <span class="icon">
                  <fa-icon [icon]="faWrench"></fa-icon>
                </span>
                Skills
              </a>
            </li>
            <li class="longer-child-item">
              <a [routerLink]="['resume/experience']">
                <span class="icon">
                  <fa-icon [icon]="faBusinessTime"></fa-icon>
                </span>
                Professional experience
              </a>
            </li>
            <li>
              <a [routerLink]="['resume/education']">
                <span class="icon">
                  <fa-icon [icon]="faUserGraduate"></fa-icon>
                </span>
                Education
              </a>
            </li>
            <li>
              <a [routerLink]="['resume/certifications']">
                <span class="icon">
                  <fa-icon [icon]="faFileCertificate"></fa-icon>
                </span>
                Certifications
              </a>
            </li>
            <li>
              <a [routerLink]="['resume/training']">
                <span class="icon">
                  <fa-icon [icon]="faLaptopCode"></fa-icon>
                </span>
                Training
              </a>
            </li>
          </ul>
        </li>
        <li class="menu__parent">
          <a [routerLink]="['hobbies']" class="menu__parent_item">
            <span class="icon">
              <fa-icon [icon]="faDiceD20"></fa-icon>
            </span>
            Hobbies
          </a>
        </li>
      </ul>
      <div class="columns is-centered social">
        <a
          class="icon first-icon"
          href="https://www.linkedin.com/in/carlosermidasantos/"
        >
          <fa-icon [inverse]="true" [icon]="faLinkedin" size="lg"> </fa-icon>
        </a>
        <a class="icon" href="https://github.com/mesosteros">
          <fa-icon [inverse]="true" [icon]="faGithub" size="lg"> </fa-icon>
        </a>
      </div>
    </aside>
    <label for="menu-toggle" class="nav-toggle">
      <span></span>
      <span></span>
      <span></span>
    </label>
    <input type="checkbox" id="menu-toggle" class="is-hidden" />
    <div class="nav-right nav-menu nav-menu-mobile">
      <a [routerLink]="['about']" class="nav-item is-tab is-hidden-tablet">
        <span class="icon"><fa-icon [icon]="faUserCircle"></fa-icon></span>
        About
      </a>
      <a [routerLink]="['resume']" class="nav-item is-tab is-hidden-tablet">
        <span class="icon"><fa-icon [icon]="faFileUser"></fa-icon></span>
        Resume
      </a>
      <a [routerLink]="['hobbies']" class="nav-item is-tab is-hidden-tablet">
        <span class="icon"><fa-icon [icon]="faDiceD20"></fa-icon></span>
        Hobbies
      </a>
      <a
        href="https://www.linkedin.com/in/carlosermidasantos/"
        class="nav-item is-tab is-hidden-tablet"
      >
        <span class="icon"
          ><fa-icon [inverse]="true" [icon]="faLinkedin" size="lg"> </fa-icon
        ></span>
      </a>
      <a
        href="https://github.com/mesosteros"
        class="nav-item is-tab is-hidden-tablet"
      >
        <span class="icon"
          ><fa-icon [inverse]="true" [icon]="faGithub" size="lg"> </fa-icon
        ></span>
      </a>
    </div>
  </div>
</nav>
