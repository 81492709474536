<div id="app">
  <div class="columns has-background-dark">
    <div
      class="column__left column is-one-third-tablet is-one-third-desktop is-one-fifth-widescreen">
      <app-nav-bar></app-nav-bar>
    </div>
    <div class="column__right column">
      <app-content></app-content>
    </div>
  </div>
</div>